<template>
  <div :class="[styles.root, {[styles.invalid]: hasInvalid}]">
    <label v-if="label" :class="styles.label" :for="textFieldId">
      {{ label }}
      <span :class="[styles.required,{[styles.hasRequired]: hasRequired}]">*</span>
    </label>
    <input
      :id="textFieldId"
      :value="modelValue"
      :class="[styles.input, styles[size]]"
      :name="name"
      :disabled="disabled"
      :type="type"
      :placeholder="placeholder"
      @input="emits('update:modelValue', $event.target.value)"
    >
    <div v-if="hasInvalid">
      <Typography
        v-for="(error, i) in errors"
        :key="i"
        variant="span"
        tag="p"
        class="text-pink"
      >
        {{ error }}
      </Typography>
    </div>
  </div>
</template>

<script lang="ts" setup>
import styles from './TextField.module.scss';
import { useId } from '#app';
import { Typography } from '~/shared/ui/Typography';
import { computed } from 'vue';

const textFieldId = useId();

interface TextFieldProps {
  modelValue?: string;
  label?: string;
  name?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'text' | 'number' | 'email' | 'password' | 'date';
  placeholder?: string;
  disabled?: boolean;
  hasRequired?: boolean;
  errors?: object;
}

interface TextFieldEmits {
  (event: 'update:modelValue', modelValue): string;
}

const emits = defineEmits<TextFieldEmits>();

const props = withDefaults(defineProps<TextFieldProps>(), {
  modelValue: null,
  label: null,
  name: null,
  placeholder: null,
  size: 'medium',
  type: 'text',
  disabled: false,
  hasRequired: true,
  errors: null,
});

const hasInvalid = computed(() => props.errors && props.errors.length)
</script>
